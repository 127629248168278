/**
 * Created by wipsteve on 25/11/2019.
 */
import Patterns from '../configs/Patterns';
import axios from 'axios';

export default class FormInviteFriend {
  get SELECTORS () {
    return {
      close: '.js-closeForm',
      trigger: '.js-inviteFriend',
      component: '.js-inviteModal',
      form: 'form',
      submit:'.js-submitInvite',
      sentSuccess: '.success-block',
    }
  }

  constructor () {
    this.inputContainer = '.form-field';
    this.cssOpenForm = 'open-form';
    this.inputErrorClass = 'js-inputError';
    this.cssFormSent = 'sent';

    this.formHandler = this.formHandler.bind(this);
  }

  init () {
    try{
      this.handleComponent();
    }catch (err){
      console.error(err)
    }
  }

  handleComponent(){
    let modalForm = document.querySelector(this.SELECTORS.component);
    let trigger = document.querySelector(this.SELECTORS.trigger);
    if(!modalForm){
      throw 'Componente (js-inviteForm) mancante in pagina'
    }else if(trigger && modalForm){
      try{
        this.modalHandler(modalForm,trigger);
        this.formHandler(modalForm);
      }catch (err){
        console.error(err);
      }

    }else{
      return false;
    }
  }
  modalHandler(modal,trigger){
    let close = modal.querySelector(this.SELECTORS.close);
    if(!close){
      throw 'Bottone close (js-closeForm) mancante dentro modale (.form-friend__wrapper)'
    }
    trigger.addEventListener('click',()=>{
      modal.classList.add(this.cssOpenForm);
    });
    close.addEventListener('click',()=>{
      modal.classList.remove(this.cssOpenForm);
    })
  }
  formHandler(modal){
    let form = modal.querySelector(this.SELECTORS.form);
    let submit = modal.querySelector(this.SELECTORS.submit);
    if(!form){
      throw `Form ${this.SELECTORS.form} mancante all'interno della modal`
    }
    let requireds = form.querySelectorAll('input:required');
    console.log(requireds);
    for (let req of requireds){
      switch (req.type) {
        case 'email':
            //req.pattern = Patterns.email.source;

        break;
        case 'text':
          req.pattern = Patterns.text.source;
        break;
      }

      req.addEventListener('input',()=>{
        let isValid = req.checkValidity();
        let errMsg = req.dataset.errMsg?req.dataset.errMsg:'';
        if(!isValid){
          console.log(`invalid input ${req.name}`);
          this.setErrorInput(req,errMsg);
        }else{
          this.setErrorInput(req,'');
        }
      })
    }
    submit.addEventListener('click', (e) => {
      let url = form.action;
      let formData = new FormData(form);
      if(form.checkValidity()){
        e.preventDefault();
          grecaptcha.ready(() => {
              grecaptcha.execute(window.recaptcha_site_key, {action: 'submit'})
                .then((token) => {
                  formData.append('gtoken', token)
                  return axios( {
                      method:'post',
                      url: url,
                      data: formData
                  })
                })
                .then((resp)=>{
                    //console.log(resp);
                    let status = resp.data.status;
                    if(status === 'success'){
                        form.reset();
                        this.toggleForm(form);
                        this.successMessage(form, resp.data.message);
                        setTimeout(()=>{
                            modal.classList.remove(this.cssOpenForm);
                            this.successMessage(form,'');
                            this.toggleForm(form);
                        },1500);
                    }
                })
                .catch((err)=>{
                    let errors = err.resp.data.errors;
                    //console.log(errors);
                    if(errors){
                        this.resetErrors();
                        for (let [key, value] of Object.entries(errors)) {
                            //console.log(key, value);
                            this.setErrorInput(key,value[0]);
                        }
                    }
                })
          });
      }
    });
  }
  toggleForm(form){
    form.classList.toggle(this.cssFormSent);
  }
  successMessage(form, message){
    let msgContainer = form.querySelector(this.SELECTORS.sentSuccess);
    msgContainer.innerText = message;
  }
  setErrorInput(inputEl,errMessage){
    let inputContainer = inputEl.closest(this.inputContainer);
    inputContainer.classList.add(this.inputErrorClass);
    let errorSpan = inputContainer.querySelector('.error');
    errorSpan.innerText = errMessage;
  }
  resetErrors(){
    let inputContainers = document.querySelectorAll(this.inputContainer);
    for(let group of inputContainers){
      if(group.classList.contains(this.inputErrorClass)){
        group.classList.remove(this.inputErrorClass);
      }
    }
  }
}
